@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Date Customize */
input.date {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.fixed-container::-webkit-scrollbar {
  width: 0;
}

/* --------------- Animation For table fix header --------------------- */

/* .fixed-table {
  animation-name: moveUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
} */

@keyframes moveUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-50);
  }
}


.table-container {
  position: relative;
  overflow-x: auto;
}
.table-container2 {
  position: relative;
  overflow-x: auto;
}

.fixed-table {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table-container td,
.table-container th {
  min-width: 120px; /* Adjust the minimum width as per your requirements */
  max-width: 180px; /* Adjust the maximum width as per your requirements */
  box-sizing: border-box;
  height: 113px;
}
.table-container2 td,
.table-container2 th {
  width: 150px; /* Adjust the width as per your requirements */
  min-width: 150px; /* Adjust the minimum width as per your requirements */
  max-width: 180px; /* Adjust the maximum width as per your requirements */
  box-sizing: border-box;
}


/* For WebKit-based browsers (Chrome, Safari, and newer Edge) */
/* You may need to adjust the scrollbar width and color as per your design */
/* .scrollbar-hidden::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar-hidden::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
} */

/* For Firefox */
/* .scrollbar-hidden {
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
} */

/* For Internet Explorer 10+ and older Edge versions */
/* .scrollbar-hidden {
  -ms-overflow-style: none;
} */


/* For WebKit-based browsers (Chrome, Safari, and newer Edge) */
.scrollbar-hidden::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar-hidden::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* For Firefox */
.scrollbar-hidden {
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* For Internet Explorer 10+ and older Edge versions */
.scrollbar-hidden {
  -ms-overflow-style: none;
}

/* Hide vertical scrollbar */
/* .scrollbar-hidden {
  overflow-y: hidden;
} */



/* Animation For Loading Data */
.truncate {
  max-width: 150px; /* set your desired max width */
  white-space: nowrap;
  overflow: hidden;
  background: white;
  text-overflow: ellipsis;
}

